<template>
    <a-modal
        :title="title"
        :width="600"
        :visible="visible"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
    >
        <a-spin :spinning="confirmLoading">
            <a-form :form="form">
                <a-form-item hidden><a-input v-decorator="['id']" /></a-form-item>
                <a-form-item label="处理笺名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input
                        placeholder="请输入处理笺名称"
                        :maxLength="20"
                        v-decorator="['name', { rules: [{ required: true, message: '请输入处理笺名称！' }] }]"
                    />
                </a-form-item>
                <a-form-item label="使用范围" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-checkbox-group style="width: 100%" v-decorator="['typeList']">
                        <a-row>
                            <a-col :span="24">
                                <a-checkbox :value="1">收文登记</a-checkbox>
                            </a-col>
                            <a-col :span="24" style="margin: 3px 0">
                                <a-checkbox :value="2">快速发文</a-checkbox>
                            </a-col>
                            <a-col :span="24">
                                <a-checkbox :value="3">拟稿发文</a-checkbox>
                            </a-col>
                        </a-row>
                    </a-checkbox-group>
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script>
import { documentTabAdd, documentTabEdit } from '@/api/modular/fileStream/processingSignature'
export default {
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 },
            },
            visible: false,
            confirmLoading: false,
            form: this.$form.createForm(this),
            title: '新增处理笺名称',
            rangs: [],
        }
    },
    methods: {
        /**
         * 提交表单
         */
        handleSubmit() {
            const {
                form: { validateFields },
            } = this
            validateFields((errors, values) => {
                if (!errors) {
                    this.confirmLoading = true
                    const params = {
                        ...values,
                    }
                    const func = params?.id ? documentTabEdit : documentTabAdd
                    func(values)
                        .then((res) => {
                            if (res.success) {
                                this.$message.success('新增成功')
                                this.confirmLoading = false
                                this.$emit('ok', values)
                                this.handleCancel()
                            } else {
                                this.$message.error(res.message || '新增失败') // + res.message
                            }
                        })
                        .finally((res) => {
                            this.confirmLoading = false
                        })
                }
            })
        },
        handleCancel() {
            this.form.resetFields()
            this.handleVisible()
        },
        handleVisible() {
            this.visible = !this.visible
        },
        // 初始化方法
        open(info) {
            this.handleVisible()
            if (info) {
                this.title = '编辑处理笺名称'
                setTimeout(() => {
                    this.form.setFieldsValue({
                        id: info.id,
                        name: info.name,
                        typeList: info.typeList,
                    })
                }, 0)
            }
        },
    },
}
</script>
